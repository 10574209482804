import moment from 'moment';

export function secondsToHms(value: any) {
  const sec: any = parseInt(value, 10);
  let hours: any = Math.floor(sec / 3600);
  let minutes: any = Math.floor((sec - hours * 3600) / 60);
  let seconds: any = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (hours == 0) {
    return minutes + ':' + seconds; // Return in MM:SS format
  } else {
    return hours + ':' + minutes + ':' + seconds; // Return in HH:MM:SS format
  }
}

export function minutesToSecond(input: any) {
  // if (input.length > 0){
  try {
    var parts: any = input.split(':'),
      minutes: any = +parts[0],
      seconds: any = +parts[1];
    return (minutes * 60 + seconds).toFixed(3);
  } catch (err) {
    return;
  }
  // }
}

export function timeToMinutes(input: any) {
  try {
    var a = input.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = +a[0] * 60 + +a[1];
    return minutes;
  } catch (err) {
    return;
  }
}

var hr: any = 0;
var min: any = 0;
var sec: any = 0;
var stoptime = true;

export function startTimer() {
  if (stoptime == true) {
    stoptime = false;
    timerCycle();
  }
}
export function stopTimer() {
  if (stoptime == false) {
    stoptime = true;
  }
}

export function timerCycle() {
  const timer: any = document.getElementById('stopwatch');
  if (stoptime == false) {
    sec = parseInt(sec);
    min = parseInt(min);
    hr = parseInt(hr);
    sec = sec + 1;
    if (sec == 60) {
      min = min + 1;
      sec = 0;
    }
    if (min == 60) {
      hr = hr + 1;
      min = 0;
      sec = 0;
    }
    if (sec < 10 || sec == 0) {
      sec = ('0' + sec).slice(-2);
    }
    if (min < 10 || min == 0) {
      min = parseInt(('0' + min).slice(-2));
    }
    if (hr < 10 || hr == 0) {
      hr = parseInt(('0' + hr).slice(-2));
    }
    timer.innerHTML = hr + ':' + min + ':' + sec;
    setTimeout(timerCycle, 1000);
  }
}

export function resetTimer() {
  console.log('reset timer');
  const timer: any = document.getElementById('stopwatch');
  timer.innerHTML = '00:00:00';
  stoptime = true;
  hr = 0;
  sec = 0;
  min = 0;
}

export function hmstoSeconds(input: any) {
  try {
    var a = input.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    return seconds;
  } catch (err) {
    return 2400;
  }
}

export const format = (time) => {
  let hours: any = Math.floor(time / 3600);
  let minutes: any = Math.floor((time % 3600) / 60);
  let seconds: any = time % 60;
  // let minutes: any = Math.floor(time / 60);
  hours = hours.toString().length === 1 ? '0' + hours : hours;
  minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
  seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
  if (hours === '00') return minutes + ':' + seconds;
  return hours + ':' + minutes + ':' + seconds;
};

export function countdownHelper(duration, display, durationType, timeUp) {
  var timer = duration,
    minutes,
    seconds;

  setInterval(function () {
    minutes = Math.floor(timer / 60);
    seconds = timer % 60;
    minutes = minutes.toString() < 10 ? '0' + minutes : minutes;
    seconds = seconds.toString() < 10 ? '0' + seconds : seconds;
    display.textContent = minutes + ':' + seconds;
    if (minutes == 0) {
      durationType.textContent = 'seconds';
    }
    if (--timer < 0) {
      timer = duration;
      timeUp();
    }
  }, 1000);
}

export function timeConvert(n) {
  var hours = parseFloat(n) / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rminutes > 0) {
    return rhours + ' hr ' + rminutes + ' min';
  }
  return rhours + ' hr ';
}

export type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const calculateTimeLeft = (date, countdownComplete) => {
  const isoDate = moment(date);
  const currentDate: any = moment();

  const difference: any = isoDate.diff(currentDate);
  const duration = moment.duration(difference);
  let timeLeft: TimeLeft = {} as TimeLeft;

  if (difference > 0) {
    timeLeft = {
      // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      // minutes: Math.floor((difference / 1000 / 60) % 60),
      // seconds: Math.floor((difference / 1000) % 60),
      days: duration?.days(),
      hours: duration?.hours(),
      minutes: duration?.minutes(),
      seconds: duration?.seconds(),
    };
  } else {
    countdownComplete();
  }

  return timeLeft;
};

export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};
