const objectToFormData = (obj, form, namespace) => {
  if (typeof obj !== 'object') return;
  const fd = form || new FormData();
  let formKey;
  Object.keys(obj).map((property) => {
    // for (let property in obj) {
    if (obj[property] !== null && obj[property] !== undefined) {
      if (!(obj[property] instanceof File)) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }
        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (typeof obj[property] === 'object') {
          objectToFormData(obj[property], fd, formKey);
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    return property;
  });
  return fd; // eslint-disable-line consistent-return
};

const formDataHelper = (data, document) => {
  let multipartData = new FormData();
  multipartData = objectToFormData(data, multipartData);
  Object.keys(document).map((each) => {
    if (Array.isArray(document[each])) {
      document[each].map((fileObj) => multipartData.append([each], fileObj));
    } else {
      multipartData.append([each], document[each]);
    }
    return null;
  });
  return multipartData;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default formDataHelper;

export const blockInvalidChar = (e) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const blockCharOnly = (e) => {
  const charCode = e.keyCode;
  if (
    (charCode <= 90 && charCode >= 65) ||
    (charCode <= 97 && charCode >= 122)
    // (charCode <= 57 && charCode >= 48) ||
    // charCode === 32 ||
    // [(',', '(', ')', '+', '-')].includes(e.key)
  ) {
    e.preventDefault();
  }
};
