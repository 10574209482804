import { useContext } from 'react';
import { OnBoardingContext } from '.';
import { useDispatch } from 'react-redux';
import { userLogin } from '@/actions/auth';
import { Button, Input } from 'antd';
import Link from 'next/link';
import GoogleLogin from 'react-google-login';

const LoginForm = () => {
  const {
    userData,
    user,
    handleFilter,
    handleLogin,
    googleResponse,
    CustomGoogleButton,
  } = useContext(OnBoardingContext);
  const dispatch = useDispatch();

  const submitForm = (event) => {
    event.preventDefault();
    dispatch(userLogin(user));
  };
  return (
    <>
      {typeof userData?.message === 'string' && userData?.message && (
        <div className="alert alert-danger">
          <p>{userData?.message}</p>
        </div>
      )}
      <form className="form" onSubmit={submitForm}>
        <div className="form-group">
          <label className="font-weight-normal">Email</label>
          <Input
            name="email"
            type="email"
            // className="form-control w-100"
            className="border-14"
            onChange={handleFilter}
            placeholder="Email"
            value={user?.email || ''}
            size="large"
          />
          {userData?.error?.email && (
            <span className="validation">
              <i className="fas fa-exclamation-triangle"></i>{' '}
              {userData?.error?.email}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Password</label>
          <Input.Password
            name="password"
            type="password"
            // className="form-control w-100"
            className="border-14"
            onChange={handleFilter}
            placeholder="Password"
            value={user?.password || ''}
            size="large"
          />
          {userData?.error?.password && (
            <span className="validation">
              <i className="fas fa-exclamation-triangle"></i>{' '}
              {userData?.error?.password}
            </span>
          )}
        </div>
        {/* <button className="btn btn-primary">Login</button> */}
        <Button
          htmlType="submit"
          size="large"
          className="btn-primary border-danger btn-block mt-2 mb-2 border-14"
        >
          Login
        </Button>
        <p className="account text-center mb-1">
          {' '}
          Don't have an account?{' '}
          <span className="text-primary" onClick={() => handleLogin('signup')}>
            <u>Sign up</u>
          </span>
        </p>
        <div className="text-center">
          <Link href="/login/forgot-password">
            <p className="forgot-password text-primary cursor-pointer d-inline-block">
              <u>Forgot Password?</u>
            </p>
          </Link>
        </div>
      </form>

      <div className="row mt-2 mb-2">
        <span className="border-option col-lg-4 d-none d-lg-block ml-4 my-auto"></span>
        <p className="option col-lg-2 my-auto mx-auto text-center"> OR </p>
        <span className="border-option d-none d-lg-block col-lg-4 my-auto"></span>
      </div>
      <GoogleLogin
        clientId={process?.env?.NEXT_PUBLIC_GOOGLE_APP_ID}
        // buttonText="SignIn With Google"
        onFailure={(err) => console.log('error', err)}
        render={(renderProps) => (
          <CustomGoogleButton onClick={renderProps.onClick} />
        )}
        onSuccess={googleResponse}
        className="btn btn-block font-weight-normal"
        // style={{ backgroundColor: 'red !important', justifyContent: 'center' }}
        cookiePolicy={'single_host_origin'}
      />
    </>
  );
};

export default LoginForm;
