import { getApp, getApps, initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

// Firebase config for Learnsic
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

// Firebase config for SIC Landing
const firebaseConfigSic = {
  apiKey: 'AIzaSyBsNziUbudNPpSVhKXEhqiRDPf3KFRdQJc',
  authDomain: 'study-info-centre.firebaseapp.com',
  projectId: 'study-info-centre',
  storageBucket: 'study-info-centre.appspot.com',
  messagingSenderId: '619525662126',
  appId: '1:619525662126:web:849c6ac1d719998763943f',
  measurementId: 'G-1NB5V7GDKT',
};

// const app = initializeApp(firebaseConfig);
// export const authentication = getAuth(app);

// Initialize the default app (Learnsic)
const appA = !getApps().some((app) => app.name === '[DEFAULT]')
  ? initializeApp(firebaseConfig)
  : getApp();

// Initialize the second app (SIC) with a unique name
const appB = !getApps().some((app) => app.name === 'appB')
  ? initializeApp(firebaseConfigSic, 'appB')
  : getApp('appB');

// Access Firebase services from Learnsic
const authentication = getAuth(appA);
const firestoreA = getFirestore(appA);

// Access Firebase services from SIC Landing
const authenticationSic = getAuth(appB);
const firestoreB = getFirestore(appB);

export { authentication, firestoreA, authenticationSic, firestoreB };
