import { CountryCodeSelector } from '@/helpers/CountryCodeSelector';
import { Button, Input, Select } from 'antd';
import { useContext } from 'react';
import { OnBoardingContext } from '.';
import useWidth from '@/helpers/widthUtil';
import { blockInvalidChar } from '@/helpers/formDataHelper';
import countries from '@/helpers/countries';
import { RootStateOrAny, useSelector } from 'react-redux';

const PhoneForm = () => {
  const { isMobile } = useWidth();
  const { Option } = Select;
  const userDataRegister = useSelector(
    (state: RootStateOrAny) => state.auth.register
  );
  const { submitPhoneForm, handleSelectFilter, errors, handleFilter, user } =
    useContext(OnBoardingContext);

  return (
    <>
      <h6 className="text-center mb-4 text-secondary">
        Please fill these fields for verification
      </h6>
      <form onSubmit={submitPhoneForm}>
        <div className="row mt-1">
          <div className={`${isMobile ? 'col' : 'col-md-5 col-lg-5 col-sm-5'}`}>
            <div className="form-group country-code-selector">
              <label className="text-left ml-1">Country Code</label>
              <CountryCodeSelector
                onChange={(val) => handleSelectFilter('country_code', val)}
                defaultValue={'+977'}
              />
            </div>

            {errors?.country_code && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.country_code}
              </span>
            )}
          </div>
          <div className={`${isMobile ? 'col' : 'col-md-7 col-lg-7 col-sm-7'}`}>
            <div className="form-group">
              <label className="text-left ml-1">Phone</label>
              <Input
                name="phone_number"
                type="number"
                onChange={handleFilter}
                placeholder="Phone Number"
                value={user?.phone_number || ''}
                size="large"
                required
                onKeyDown={blockInvalidChar}
                className="border-14"
                autoComplete="off"
              />
            </div>
            {errors?.phone_number && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {errors?.phone_number}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Country</label>
              <Select
                showSearch
                style={{ width: '100%' }}
                value={user?.country || ''}
                // className="border-14"
                placeholder="Select Country"
                optionFilterProp="children"
                onChange={(val) => handleSelectFilter('country', val)}
              >
                {Object.entries(countries).map((each, idx) => (
                  <Option key={idx} value={each[1]}>
                    {each[1]}
                  </Option>
                ))}
              </Select>

              {/* <Input
                name="country"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="Country"
                value={user?.country || ''}
                size="large"
                required
              /> */}
            </div>
            {userDataRegister?.error?.country && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.country}
              </span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>City</label>
              <Input
                name="city"
                type="text"
                className="border-14"
                onChange={handleFilter}
                placeholder="City"
                value={user?.city || ''}
                size="large"
                required
              />
            </div>
            {userDataRegister?.error?.city && (
              <span className="validation mb-2">
                <i className="fas fa-exclamation-triangle"></i>{' '}
                {userDataRegister?.error?.city}
              </span>
            )}
          </div>
        </div>
        <Button
          htmlType="submit"
          size="large"
          className="btn-primary border-danger btn-block mt-2 mb-2 border-14"
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default PhoneForm;
