import { Input } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { country_codes } from './countries';

export const CountryCodeSelector = ({
  width = 6,
  onChange,
  placeholder = 'Country Code',
  defaultValue,
  disabled=false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const inputField = useRef();
  const [value, setValue] = useState(country_codes[defaultValue]);

  useEffect(() => {
    let temp = Object.values(country_codes)?.find(
      (each) => each.mobileCode == defaultValue
    );
    setValue(temp);
  }, []);

  return (
    <div
      className={`bg-white p-2 border-14 border-muted border d-flex col-lg-12 p-0 justify-content-${
        !!value ? 'start' : 'start'
      } align-items-center border-14`}
    >
      {!!value ? (
        <img
          loading="lazy"
          src={`/images/flags/${value?.code?.toLowerCase()}.svg`}
          alt={value?.code}
          // height="20"
          width="20"
          className="ml-1 p-0"
        />
      ) : null}{' '}
      <Input
        className={`ml-1 p-0 col-lg-${width}`}
        style={{ border: 'none', boxShadow: 'none' }}
        ref={inputField}
        // wrapperstyle={{ display: 'grid' }}
        // onChange={() => {}}
        value={!!value ? value?.mobileCode : ''}
        onFocus={(_) => setIsVisible(true)}
        onBlur={(_) => setTimeout((_) => setIsVisible(false), 500)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {/* <ChevronDown className="mr-0" onClick={(_) => inputField.current.focus()} /> */}
      {isVisible ? (
        <div
          className="ant-select-dropdown mr-2"
          style={{
            minWidth: 150,
            width: 150,
            left: 0,
            top: 50,
            maxHeight: 256,
            overflowY: 'auto',
            overflowAnchor: 'none',
          }}
        >
          {Object.keys(country_codes)?.map((key) => (
            <div
              onClick={(_) => {
                onChange(country_codes[key]?.mobileCode);
                setValue(country_codes[key]);
                setIsVisible(false);
              }}
              role="button"
              key={key}
              className="ant-select-item ant-select-item-option custom-input"
            >
              <div className="ant-select-item-option-content">
                <img
                  loading="lazy"
                  src={`/images/flags/${country_codes[
                    key
                  ]?.code?.toLowerCase()}.svg`}
                  alt={key}
                  height="20"
                  width="20"
                />
                &nbsp;
                {country_codes[key]?.mobileCode}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
