import { useContext, useEffect, useState } from 'react';
import { OnBoardingContext } from '.';
import { Alert, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { requestSignupOtp, verifySignupOtp } from '@/actions/auth';
import { countdownHelper } from '@/helpers/timeHelper';

const OtpForm = () => {
  const dispatch = useDispatch();
  const [otpLoader, setOTPLoader] = useState(false);
  const [verifyError, setverifyError] = useState(null);
  const [resendClick, setResendClick] = useState(false);

  useEffect(() => {
    return () => setResendClick(false);
  }, []);

  const {
    OTP,
    setOTP,
    isNewUserNepali,
    isGoogleLogin,
    processSignup,
    formData,
    phoneValidation,
    expandOtpForm,
    disableResend,
    setDisableResend,
    handleSubmitGoogleLogin,
  } = useContext(OnBoardingContext);

  const {
    auth: { user, verifySignupOtpStatus },
  } = useSelector((state: any) => ({
    auth: state.auth,
  }));

  const verifyNepalOtp = () => {
    dispatch(verifySignupOtp({ phone_number: user?.phone_number, code: OTP }));
  };

  const verifyOTP = (e) => {
    let confirmationResult = window.confirmationResult;
    setOTPLoader(true);
    confirmationResult
      .confirm(OTP)
      .then((result) => {
        // User signed in successfully.
        message.success('Verified successfully');
        setverifyError(null);
        isGoogleLogin
          ? handleSubmitGoogleLogin('exchange/google-oauth2/', formData)
          : processSignup();
        setOTPLoader(false);
      })
      .catch((error) => {
        message.error('Verification failed');
        error?.code == 'auth/invalid-verification-code'
          ? setverifyError('Invalid verification code')
          : setverifyError(error?.message || 'Verification Failed');
        setOTPLoader(false);
      });
  };

  const timeUp = () => {
    setDisableResend(false);
  };

  const otpClick = (e) => {
    if (!resendClick) setResendClick(true);
    setDisableResend(true);
    // submitSignupForm(e);
    var waitInterval = 60 * 3,
      display = document.querySelector('#countdownToOtp'),
      durationType = document.querySelector('#durationType');
    countdownHelper(waitInterval, display, durationType, timeUp);
    isNewUserNepali
      ? dispatch(requestSignupOtp({ phone_number: user?.phone_number }))
      : phoneValidation(e);
    setverifyError('');
  };

  const handleOtpChange = (e) => {
    // clearState();
    const { value } = e.target;
    if (value.length < 7) {
      const message = value.slice(0, 6);
      setOTP(message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center container">
        <div className="py-3 px-3">
          <div className="title">
            <h5 className="banner-form-title">Mobile phone verification</h5>
          </div>
          <div className="text-center">
            Enter the code we just sent on your mobile phone{' '}
            <b className="text-danger">
              {user?.country_code}-{user?.phone_number}
            </b>
          </div>
          <input
            className="form-control mx-auto mt-5 p-2"
            id="phone_otp"
            onChange={(e) => handleOtpChange(e)}
            type="number"
            value={OTP}
          />
          <br />
          <Button
            className="ant-btn btn-primary border-14 border-danger mt-2 btn-block"
            onClick={isNewUserNepali ? verifyNepalOtp : verifyOTP}
            loading={
              isNewUserNepali ? verifySignupOtpStatus === 'request' : otpLoader
            }
            disabled={OTP?.length < 6}
          >
            Verify
          </Button>
          {verifyError ? (
            <Alert type="error" className="mt-4" message={verifyError} />
          ) : (
            ''
          )}
          <div className="text-center mt-3">
            <span className="d-block mobile-text">Didn't receive the code?</span>
            <Button
              className="button-outline-secondary cursor-pointer border-none mt-1"
              onClick={otpClick}
              disabled={disableResend}
            >
              Resend
            </Button>
          </div>
        </div>
      </div>
      <div
        className={`text-center ${expandOtpForm && disableResend ? '' : 'd-none'}`}
      >
        {disableResend && resendClick && (
          <Alert
            message="Verification code has been sent successfully"
            type="success"
            className="mb-1"
          />
        )}
        Resend OTP in <span id="countdownToOtp">03:00</span>{' '}
        <span id="durationType">minutes</span>!
      </div>
    </>
  );
};

export default OtpForm;
